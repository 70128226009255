import * as React from 'react';
import NumberFormat from 'react-number-format';
import { getCurrentFiscalYear } from '../../utils';
import { CurrencyLabel } from '../shared';

export interface QuarterData {
  current: number;
  previous: number;
  percentDifference: number;
  difference: number;
}

export interface QuarterlyStats {
  quarters: {
    q1: QuarterData;
    q2: QuarterData;
    q3: QuarterData;
    q4: QuarterData;
  };
  annual: QuarterData;
}

interface QuarterlyPaymentRowProps {
  stats: QuarterlyStats;
  currentYear?: number;
  showHeaders?: boolean;
}

export const QuarterlyPaymentRow = ({ stats, currentYear = getCurrentFiscalYear(), showHeaders = true }: QuarterlyPaymentRowProps): JSX.Element => {
  const getComparison = (quarter: QuarterData) => {
    const { percentDifference, difference, previous } = quarter;

    if (difference === 0 || percentDifference === -100 || previous === 0) {
      return <div className="small">—</div>;
    }

    return (
      <div className={`small ${percentDifference > 0 ? 'text-success' : 'text-danger'}`}>
        <NumberFormat
          decimalScale={0}
          suffix="%"
          displayType="text"
          value={percentDifference}
        /> (<CurrencyLabel value={difference} />)
      </div>
    );
  };

  const getAnnualComparison = () => {
    if (
      (stats.quarters.q1.current === 0 ||
        stats.quarters.q2.current === 0 ||
        stats.quarters.q3.current === 0 ||
        stats.quarters.q4.current === 0) &&
      currentYear === getCurrentFiscalYear()
    ) {
      return '—';
    }
    return getComparison(stats.annual);
  };

  return (
    <div className="table-responsive">
      <table className="table">
        {showHeaders && (
          <thead>
            <tr>
              <th className="text-center">
                Q1
                <div className="small text-muted">Jul - Sep</div>
              </th>
              <th className="text-center">
                Q2
                <div className="small text-muted">Oct - Dec</div>
              </th>
              <th className="text-center">
                Q3
                <div className="small text-muted">Jan - Mar</div>
              </th>
              <th className="text-center">
                Q4
                <div className="small text-muted">Apr - Jun</div>
              </th>
              <th className="text-center">
                Annual
                <div className="small text-muted">Jul - Jun</div>
              </th>
            </tr>
          </thead>
        )}
        <tbody>
          <tr>
            <td className="text-center">
              {stats.quarters.q1.current ? <CurrencyLabel value={stats.quarters.q1.current} /> : '—'}
              <div className="text-muted small">
                LY <CurrencyLabel value={stats.quarters.q1.previous} />
              </div>
              {getComparison(stats.quarters.q1)}
            </td>
            <td className="text-center">
              {stats.quarters.q2.current ? <CurrencyLabel value={stats.quarters.q2.current} /> : '—'}
              <div className="text-muted small">
                LY <CurrencyLabel value={stats.quarters.q2.previous} />
              </div>
              {getComparison(stats.quarters.q2)}
            </td>
            <td className="text-center">
              {stats.quarters.q3.current ? <CurrencyLabel value={stats.quarters.q3.current} /> : '—'}
              <div className="text-muted small">
                LY <CurrencyLabel value={stats.quarters.q3.previous} />
              </div>
              {getComparison(stats.quarters.q3)}
            </td>
            <td className="text-center">
              {stats.quarters.q4.current ? <CurrencyLabel value={stats.quarters.q4.current} /> : '—'}
              <div className="text-muted small">
                LY <CurrencyLabel value={stats.quarters.q4.previous} />
              </div>
              {getComparison(stats.quarters.q4)}
            </td>
            <td className="text-center">
              <CurrencyLabel value={stats.annual.current} />
              <div className="text-muted small">
                LY <CurrencyLabel value={stats.annual.previous} />
              </div>
              {getAnnualComparison()}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
