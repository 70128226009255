import { faCalendar, faFilter, faInfoCircle, faSpinner, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { Field, Form, Formik, FormikProps } from 'formik';
import { isEqual, isNil, omitBy } from 'lodash';
import * as querystring from 'query-string';
import * as React from 'react';
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import { usePerformanceQueryParams } from '.';
import { UserContext } from '../../contexts';
import { Insignia, ProductCategory, Vendor } from '../../shared';
import { formattedFiscalYear } from '../../utils';
import { PartialRepresentation } from '../Royalties/RoyaltyAnalytics/PartialRepresentation';
import { GatewayModal, InsigniaTypeahead, ModalType, ProductCategoryTypeahead, useCancelToken, VendorTypeahead } from '../shared';

interface IProps {
  title: string;
  subTitle: string;
  isAggregate: boolean;
  isCurrent: boolean;
  hideFilters?: boolean;
  hideFilterTags?: boolean;
  hideQuarter?: boolean;
  hideYear?: boolean;
  hideStartEndDate?: boolean;
  yearsOverride?: number[];
  info?: boolean;
  infoOnClick?: () => void;
  children?: React.ReactNode;
  loading?: boolean;
}

interface ParamLabels {
  value: any;
  name: string;
  key: string;
}

const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];

export const PerformanceTitleFilters = (props: IProps) => {
  const [loading, setLoading] = React.useState(true);
  const [loadingDates, setLoadingDates] = React.useState(true);
  const [paramLabels, setParamLabels] = React.useState<ParamLabels[]>([]);
  const [defaultDates, setDefaultDates] = React.useState({ startMonth: 1, startYear: null, endMonth: 1, endYear: null });
  const [dateModal, setDateModal] = React.useState(false);
  const [filterModal, setFilterModal] = React.useState(false);
  const location = useLocation();
  const history = useHistory();
  const user = React.useContext(UserContext);
  const performanceQP = usePerformanceQueryParams();
  const cancelToken = useCancelToken();
  const query = querystring.parse(location.search);

  React.useEffect(() => {
    getDefaultDates();
  },              []);

  React.useEffect(() => {
    getLabels();
  },              [location.search]);

  const getDefaultDates = async () => {
    cancelToken.cancelToken();
    const newToken = Axios.CancelToken.source();
    cancelToken.setToken(newToken);

    try {
      const qs = performanceQP.toApi('');
      const response = await Axios.get(`/api/performance-reports/startEndDate?${qs}`, {
        cancelToken: newToken.token,
      });
      const { startMonth, startYear, endMonth, endYear } = response.data;
      setDefaultDates({ startMonth, startYear, endMonth, endYear });
      setLoadingDates(false);
    } catch (error) {
      setLoadingDates(false);
      console.error('Error fetching default dates:', error);
    }
  };

  const getLabels = async () => {
    cancelToken.cancelToken();
    const newToken = Axios.CancelToken.source();
    cancelToken.setToken(newToken);

    const { category, channel, retailer, vendor, vendorIds, retailerIds, categoryIds, insignia } = performanceQP.params;
    if (!category && !channel && !retailer && !vendor && !vendorIds && !retailerIds && !categoryIds && !insignia) {
      return;
    }
    setLoading(true);
    const qs = performanceQP.toApi('topselling');
    try {
      const response = await Axios.get(`/api/performance-reports/queryLabels?${qs}`, {
        cancelToken: newToken.token,
      });
      const data = response.data.data;
      const pl = [];
      for (const key of Object.keys(data)) {
        if (Array.isArray(data[key])) {
          for (const vendor of data[key]) {
            pl.push({
              key,
              name: vendor.name,
              value: vendor.value,
            });
          }
        } else {
          pl.push({
            key,
            name: data[key].name,
            value: data[key].value,
          });
        }
      }
      setParamLabels(pl);
    } catch (error) {
      console.error('Error fetching labels:', error);
    }
    setLoading(false);
  };

  const createActionButton = (key: string, value: string) => {
    const filterLabel = getFilterLabel(key, value);
    return (
      <button
        style={{ marginLeft: 10 }}
        className="btn btn-primary"
        onClick={() => removeFilter(key, value)}
      >
        <FontAwesomeIcon icon={faTimes} /> &nbsp; {filterLabel}
      </button>
    );
  };

  const getFilterLabel = (key: any, value: string) => {
    if (['state'].includes(key)) {
      return value;
    }
    const paramLabel = paramLabels.find(l => l.key === key && String(l.value) === String(value));
    if (paramLabel) {
      return paramLabel.name;
    }
    if (loading) {
      return <FontAwesomeIcon icon={faSpinner} spin />;
    }
    return value;
  };

  const removeFilter = (key: 'category' | 'categoryIds' | 'channel' | 'year' | 'quarter' | 'state' | 'vendor' | 'retailer' | 'vendorIds' | 'retailerIds' | 'startDate' | 'endDate' | 'startMonth' | 'startYear' | 'endMonth' | 'endYear' | 'insignia', idToRemove?: string) => {
    const currentParams = querystring.parse(location.search);
    if (idToRemove && (key === 'vendorIds' || key === 'retailerIds' || key === 'categoryIds')) {
      const ids = typeof currentParams[key] === 'string' ? currentParams[key].split(',') : [];
      let updatedIds = ids.filter((id: string) => id !== idToRemove);
      if (updatedIds.length === 0) {
        delete currentParams[key];
        updatedIds = null;
      } else {
        currentParams[key] = updatedIds.join(',');
      }
      updatedIds = updatedIds ? updatedIds.join(',') : null;
      const updatedParams = { ...currentParams, [key]: updatedIds };
      const cleaned = omitBy(updatedParams, isNil);
      const qs = querystring.stringify(cleaned);
      history.push(`${location.pathname}?${qs}`);
    } else {
      const qs = performanceQP.toQueryString([{ label: key, value: null }]);
      history.push(`${location.pathname}?${qs}`);
    }
  };
  const setDateRange = (values: any) => {
    const d = { startMonth: Number(values.startMonth), startYear: Number(values.startYear), endMonth: Number(values.endMonth), endYear: Number(values.endYear) };
    const p = performanceQP.params;
    let qs = '';
    if (isEqual(d, defaultDates)) {
      p.startMonth = null;
      p.startYear = null;
      p.endMonth = null;
      p.endYear = null;
      qs = performanceQP.toQueryString();
    } else {
      p.startMonth = d.startMonth;
      p.startYear = d.startYear;
      p.endMonth = d.endMonth;
      p.endYear = d.endYear;
      qs = performanceQP.toQueryString([
        { label: 'startMonth', value: d.startMonth },
        { label: 'startYear', value: d.startYear },
        { label: 'endMonth', value: d.endMonth },
        { label: 'endYear', value: d.endYear },
      ]);
    }
    history.push(`${location.pathname}?${qs}`);
    setDateModal(false);
  };

  const setYear = (year: any) => {
    const qs = performanceQP.toQueryString([{ label: 'year', value: year }]);
    history.push(`${location.pathname}?${qs}`);
  };

  const getLabelValue = (k: any) => {
    const l = paramLabels.find(l => l.key === k);
    if (l) {
      return l.name;
    }
    return '';
  };

  const filters = Object.keys(query).filter(k => !['page', 'year', 'quarter', 'sort', 'startDate', 'endDate', 'startMonth', 'startYear', 'endMonth', 'endYear'].includes(k)).flatMap((k: string) => {
    const value = query[k];
    if (k === 'vendorIds' || k === 'retailerIds' || k === 'categoryIds' || k === 'category' || k === 'retailer' || k === 'vendor') {
      return typeof value === 'string' ? value.split(',').map((id) => {
        const label = paramLabels.find(label => label.value === parseInt(id, 10));
        const name = label ? label.name : id;
        return createActionButton(k, name);
      }) : [];
    }
    // For other filters
    const label = paramLabels.find(label => label.value === value);
    const name = label ? label.name : value;
    return [createActionButton(k, name)];
  });

  const years = [];
  const fiscalYears = [];
  const currentYear = new Date().getFullYear();
  const startYear = props.yearsOverride ? Math.min(...props.yearsOverride) : 2019;
  const endYear = props.yearsOverride ? Math.max(...props.yearsOverride) : currentYear + 1;
  const yearOptions: any[] = [];
  for (let y = startYear; y <= endYear; y++) {
    years.push(<MenuItem key={y} eventKey={y}>{y}</MenuItem>);
    yearOptions.push(<option key={y} value={y}>{y}</option>);
  }

  const date = new Date();
  let year;
  if (date.getMonth() >= 9) {
    year = date.getFullYear();
  } else {
    year = date.getFullYear() - 1;
  }

  const currentYearFilter = query['year'] || formattedFiscalYear(year);

  if (props.yearsOverride) {
    props.yearsOverride.forEach((y) => {
      const yearValue = formattedFiscalYear(y);
      fiscalYears.push(<MenuItem key={yearValue} eventKey={yearValue} >{yearValue}</MenuItem>);
    });
  } else {
    for (let i = 0; i <= (year - 2018); i++) {
      const yearToAdd = 2018 + i;
      const yearValue = formattedFiscalYear(yearToAdd);
      fiscalYears.push(<MenuItem key={yearValue} eventKey={yearValue} >{yearValue}</MenuItem>);
    }
  }

  const info = props.info ?
    <FontAwesomeIcon
      style={{ cursor: 'pointer', fontSize: 16 }}
      className="text-primary"
      icon={faInfoCircle}
      onClick={props.infoOnClick}
    /> : null;

  const getCurrentQuarter = () => {
    const currentMonth = new Date().getMonth() + 1;
    return Math.ceil(currentMonth / 3);
  };

  const isCurrentQuarterOrLater = (month: number, year: number) => {
    const currentYear = new Date().getFullYear();
    const currentQuarter = getCurrentQuarter();
    const selectedQuarter = Math.ceil(month / 3);

    if (year > currentYear) {
      return true;
    }

    if (year === currentYear && selectedQuarter >= currentQuarter) {
      return true;
    }

    return false;
  };

  const selectedStart = { year: performanceQP.params.startYear || defaultDates.startYear, month: performanceQP.params.startMonth || defaultDates.startMonth };
  const selectedEnd = { year: performanceQP.params.endYear || defaultDates.endYear, month: performanceQP.params.endMonth || defaultDates.endMonth };

  const isCurrent = isCurrentQuarterOrLater(selectedEnd.month || 0, selectedEnd.year || 0);
  const monthOptions = monthNames.map((m, i) => <option key={i + 1} value={i + 1}>{m}</option>);
  const insigniaLicensor = user.type === 'client' ? user.account.id : performanceQP.params.licensorId;

  return (
    <div style={{ marginBottom: 20 }}>
      <h3 className="no-margin"><strong>{props.title} {info}</strong></h3>
      <p className="text-muted small">{props.subTitle}</p>
      {props.isAggregate ? <PartialRepresentation /> : null}
      {props.hideFilters ? null : (
        <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
          {props.hideStartEndDate ? null : (
            <button onClick={() => setDateModal(true)} className="btn btn-default">
              { !loadingDates ? (<>
                {monthNames[selectedStart.month - 1]} {selectedStart.year} - {monthNames[selectedEnd.month - 1]} {selectedEnd.year}
              </>) : 'Loading ...'
            }
            </button>

          )}
          {props.hideStartEndDate ? null : (
            <button onClick={() => setFilterModal(true)} style={{ marginLeft: 10 }} className="btn btn-default">
              <FontAwesomeIcon icon={faFilter} />
            </button>

          )}
          {props.hideYear ? null : (
            <DropdownButton
              style={{ marginRight: '10px' }}
              id="year-dropdown"
              bsStyle={'default'}
              title={currentYearFilter}
              onSelect={(year: any) => {
                setYear(year);
              }}
            >
              {fiscalYears}
            </DropdownButton>
          )}
          {!props.hideFilterTags && filters}
          {props.children}
        </div>
      )}
      {(props.hideStartEndDate === true ? props.isCurrent : isCurrent) ? (
        <div
          className="alert alert-danger small"
          style={{ marginTop: 15, color: 'white', padding: 8, background: '#e06026' }}
        >
          <FontAwesomeIcon icon={faCalendar} /> &nbsp;
          The selected period is in progress. Analytics will adjust as sales are reported.
        </div>
      ) : null}
      <GatewayModal title="Report Range" shown={dateModal} onClose={() => setDateModal(false)} type={ModalType.Primary}>
        <Formik
          onSubmit={setDateRange}
          initialValues={{ startMonth: selectedStart.month, startYear: selectedStart.year, endMonth: selectedEnd.month, endYear: selectedEnd.year }}
        >
          {(formProps: FormikProps<any>) => (
            <Form>
              <Modal.Body>
                <div className="form-group">
                  <label>Start</label>
                  <div className="row">
                    <div className="col-md-6">
                      <Field component="select" name="startMonth" className="form-control">
                        {monthOptions}
                      </Field>
                    </div>
                    <div className="col-md-6">
                      <Field component="select" name="startYear" className="form-control">
                        {yearOptions}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>End</label>
                  <div className="row">
                    <div className="col-md-6">
                      <Field component="select" name="endMonth" className="form-control">
                        {monthOptions}
                      </Field>
                    </div>
                    <div className="col-md-6">
                      <Field component="select" name="endYear" className="form-control">
                        {yearOptions}
                      </Field>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button type="button" onClick={() => setDateModal(false)} className="btn btn-default pull-left">Cancel</button>
                <button type="submit" className="btn btn-primary pull-right">Set Report Range</button>

              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </GatewayModal>
      <GatewayModal title="Filter" shown={filterModal} onClose={() => setFilterModal(false)} type={ModalType.Primary}>
        <Formik
          onSubmit={(values) => {
            const updates: any = [];
            const vendor = values.vendor;
            if (vendor.length) {
              updates.push({ value: vendor[0].id, label: 'vendor' });
            } else {
              updates.push({ value: null, label: 'vendor' });
            }
            const retailer = values.retailer;
            if (retailer.length) {
              updates.push({ value: retailer[0].id, label: 'retailer' });
            } else {
              updates.push({ value: null, label: 'retailer' });
            }
            const category = values.category;
            if (category.length) {
              updates.push({ value: category[0].id, label: 'category' });
            } else {
              updates.push({ value: null, label: 'category' });
            }
            const insignia = values.insignia;
            if (insignia.length) {
              updates.push({ value: insignia[0].id, label: 'insignia' });
            } else {
              updates.push({ value: null, label: 'insignia' });
            }
            const queryString = performanceQP.toQueryString(updates);
            history.push(`${location.pathname}?${queryString}`);
            setFilterModal(false);
          }}
          initialValues={{
            vendor: performanceQP.params.vendor ? [new Vendor({ id: performanceQP.params.vendor, name: getLabelValue('vendor'), short_name: getLabelValue('vendor') })] : [],
            retailer: performanceQP.params.retailer ? [new Vendor({ id: performanceQP.params.retailer, name: getLabelValue('retailer'), short_name: getLabelValue('retailer') })] : [],
            insignia: performanceQP.params.insignia ? [new Insignia({ id: performanceQP.params.insignia, title: getLabelValue('insignia') })] : [],
            category: performanceQP.params.category ? [new ProductCategory({ id: performanceQP.params.category, name: getLabelValue('category') })] : [],
          }}
        >
          {(formProps: FormikProps<any>) => (
            <Form>
              <Modal.Body>
                <div className="form-group">
                  <label>Vendor</label>
                  <VendorTypeahead onChange={v => formProps.setFieldValue('vendor', v)} multiple={false} selected={formProps.values.vendor} />
                </div>
                <div className="form-group">
                  <label>Retailer</label>
                  <VendorTypeahead isRetailers={true} onChange={v => formProps.setFieldValue('retailer', v)} multiple={false} selected={formProps.values.retailer} />
                </div>
                <div className="form-group">
                  <label>Product Category</label>
                  <ProductCategoryTypeahead selected={formProps.values.category} onChange={c => formProps.setFieldValue('category', c)} multiple={false} />
                </div>
                <div className="form-group">
                  <label>Insignia</label>
                  {insigniaLicensor ? <InsigniaTypeahead licensorId={insigniaLicensor} onChange={i => formProps.setFieldValue('insignia', i)} multiple={false} selected={formProps.values.insignia} /> : null}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button type="button" onClick={() => setFilterModal(false)} className="btn btn-default pull-left">Cancel</button>
                <button type="submit" className="btn btn-primary pull-right">Filter Results</button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </GatewayModal>
    </div>
  );
};
