import Axios from 'axios';
import * as queryString from 'query-string';
import * as React from 'react';
import { useContext } from 'react';
import { useLocation, useParams } from 'react-router';
import { UserContext } from '../../contexts';
import { AffinityImage } from '../../shared';
import { addHttp, removeHttpWww } from '../../utils';
import { FullContent } from '../ContentFrame';
import { apiToPerformanceStats, CategoryPerformance, ChannelPerformance, DefaultPerformanceStats, PerformanceStats, RetailerPerformance, usePerformanceQueryParams } from '../Dashboard';
import { PerformanceSummaryRow } from '../Dashboard/PerformanceSummaryRow';
import { QuarterlyPaymentRow, QuarterlyStats } from '../Dashboard/QuarterlyPaymentRow';
import TopSellingDesignRow from '../Dashboard/TopSellingDesignRow';
import { LoadingSpinner } from '../shared';

interface RouteParams {
  licensorId?: string;
  vendorId: string;
}

export const VendorDetailPage = (): JSX.Element => {
  const user = useContext(UserContext);
  const location = useLocation();
  const performanceQP = usePerformanceQueryParams();
  const [stats, setStats] = React.useState<PerformanceStats>(DefaultPerformanceStats);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const [vendorDetails, setVendorDetails] = React.useState<any>(null);
  const [vendorStats, setVendorStats] = React.useState<QuarterlyStats | null>(null);
  const [totals, setTotals] = React.useState<{
    sales: { previous: { total: number, filtered: number }, current: { total: number, filtered: number } },
    royalties: { previous: { total: number, filtered: number }, current: { total: number, filtered: number } },
    units: { previous: { total: number, filtered: number }, current: { total: number, filtered: number } },
    avgUnitSales: { current: number, previous: number },
    avgUnitRoyalties: { current: number, previous: number },
  }>({
    sales: { previous: { total: 0, filtered: 0 }, current: { total: 0, filtered: 0 } },
    royalties: { previous: { total: 0, filtered: 0 }, current: { total: 0, filtered: 0 } },
    units: { previous: { total: 0, filtered: 0 }, current: { total: 0, filtered: 0 } },
    avgUnitSales: { current: 0, previous: 0 },
    avgUnitRoyalties: { current: 0, previous: 0 },
  });

  const routeParams = useParams<RouteParams>();
  const vendorId = routeParams.vendorId;
  const licensorId = routeParams.licensorId;
  const accountId = licensorId || (user && user.account ? user.account.id : undefined);

  React.useEffect(
    () => {
      getData();
    },
    [location.search],
  );

  const getData = async (): Promise<void> => {
    setLoading(true);
    setError(null);

    try {
      const paramFilters = [];
      if (accountId) {
        paramFilters.push({ label: 'account_id', value: accountId });
      }
      paramFilters.push({ label: 'vendor_id', value: vendorId });
      const qs = performanceQP.toApi('licensee', paramFilters);
      const salesDataUrl = `/api/performance-reports/sales-data?${qs}`;
      const vendorDetailsUrl = `/api/vendors/${vendorId}?include=owner`;

      const vendorResponse = await fetch(vendorDetailsUrl);

      if (!vendorResponse.ok) {
        setError(`Error fetching vendor details: ${vendorResponse.status} ${vendorResponse.statusText}`);
        setLoading(false);
        return;
      }

      const vendorData = await vendorResponse.json();

      if (vendorData && vendorData.data) {
        const vendor = vendorData.data;

        if (vendor.image && !vendor.image.getSize) {
          vendor.image = AffinityImage.fromApi(vendor.image);
        }

        setVendorDetails(vendor);

        const [salesResponse] = await Promise.all([
          fetch(salesDataUrl),
          fetchPaymentData(),
        ]);

        if (!salesResponse.ok) {
          setError(`Error fetching sales data: ${salesResponse.status} ${salesResponse.statusText}`);
          setLoading(false);
          return;
        }

        const analytics = await salesResponse.json();

        if (analytics) {
          const data = analytics.data;
          const s = apiToPerformanceStats(data);
          setTotals({
            sales: {
              previous: { total: Number(data.totals.yoy_total_sales), filtered: Number(data.totals.yoy_total_sales) },
              current: { total: Number(data.totals.total_sales), filtered: Number(data.totals.total_sales) },
            },
            royalties: {
              previous: { total: Number(data.totals.yoy_total_royalties), filtered: Number(data.totals.yoy_total_royalties) },
              current: { total: Number(data.totals.total_royalties), filtered: Number(data.totals.total_royalties) },
            },
            units: {
              previous: { total: Number(data.totals.yoy_total_units), filtered: Number(data.totals.yoy_total_units) },
              current: { total: Number(data.totals.total_units), filtered: Number(data.totals.total_units) },
            },
            avgUnitSales: {
              previous: Number(data.totals.yoy_avg_unit_sales),
              current: Number(data.totals.avg_unit_sales),
            },
            avgUnitRoyalties: {
              previous: Number(data.totals.yoy_avg_unit_royalties),
              current: Number(data.totals.avg_unit_royalties),
            },
          });
          setStats(s);
        }
      } else {
        setError('Vendor details not found');
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
      setLoading(false);
    }
  };

  const fetchPaymentData = async () => {
    try {
      const filters = queryString.parse(location.search);
      const yearParam = filters.year ? `year=${filters.year}` : '';
      const accountParam = accountId ? `account_id=${accountId}` : '';

      const params = [accountParam, yearParam].filter(Boolean).join('&');
      const apiUrl = `/api/performance-reports/vendor-payments/${vendorId}?${params}`;

      const response = await Axios.get(apiUrl);
      const data = response.data;

      if (data && data.vendor) {
        setVendorStats({
          quarters: {
            q1: data.vendor.quarters['1'],
            q2: data.vendor.quarters['2'],
            q3: data.vendor.quarters['3'],
            q4: data.vendor.quarters['4'],
          },
          annual: data.vendor.annual,
        });
      } else {
        setVendorStats(null);
      }

      return response;
    } catch (error) {
      console.error('Error fetching vendor payment data:', error);
      throw error;
    }
  };

  const calculatePercentage = (current: number, previous: number): number => {
    if (previous === 0) return 0;
    return ((current - previous) / previous) * 100;
  };

  const salesPercentage = calculatePercentage(totals.sales.current.filtered, totals.sales.previous.filtered);
  const royaltiesPercentage = calculatePercentage(totals.royalties.current.filtered, totals.royalties.previous.filtered);
  const unitsPercentage = calculatePercentage(totals.units.current.filtered, totals.units.previous.filtered);
  const avgUnitSalesPercentage = calculatePercentage(totals.avgUnitSales.current, totals.avgUnitSales.previous);
  const avgUnitRoyaltiesPercentage = calculatePercentage(totals.avgUnitRoyalties.current, totals.avgUnitRoyalties.previous);

  const getVendorsUrl = (): string => {
    if (user && user.type === 'admin' && routeParams.licensorId) {
      return `/clients/${routeParams.licensorId}/vendors`;
    }
    return '/vendors';
  };

  const breadcrumbs = [
    { name: 'Vendors', url: getVendorsUrl() },
    { name: 'Performance' },
  ];

  if (loading) {
    return (
      <FullContent breadcrumbs={breadcrumbs}>
        <div className="panel panel-portal">
          <div className="panel-body">
            <LoadingSpinner />
          </div>
        </div>
      </FullContent>
    );
  }

  if (error) {
    return (
      <FullContent breadcrumbs={breadcrumbs}>
        <div className="panel panel-portal">
          <div className="panel-body">
            <h4 className="text-center text-muted">No Vendor Found.</h4>
          </div>
        </div>
      </FullContent>
    );
  }

  return (
    <FullContent breadcrumbs={breadcrumbs}>
      <div className="panel panel-portal">
        {vendorDetails && (
          <div className="panel-body">
            <div className="table-responsive">
              <table className="table table-portal">
                <thead>
                  <tr>
                    <th>Vendor</th>
                    <th>Location</th>
                    <th>Website</th>
                    <th>Contact</th>
                    <th>Email</th>
                    <th>Phone</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="load-account" style={{ zIndex: 2 }}>
                        <img
                          className="pull-left hidden-sm hidden-xs"
                          src={vendorDetails.image ? vendorDetails.image.getSize('th') : 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/alp-icon.png'}
                          alt={`${vendorDetails.short_name} logo`}
                        />
                        <strong>{vendorDetails.short_name}</strong>
                      </div>
                    </td>
                    <td className="text-muted">
                      {vendorDetails.city && vendorDetails.state ? `${vendorDetails.city}, ${vendorDetails.state}` : ''}
                    </td>
                    <td>
                      {vendorDetails.website && (
                        <a target="_blank" href={addHttp(vendorDetails.website)}>
                          {removeHttpWww(vendorDetails.website)}
                        </a>
                      )}
                    </td>
                    <td>
                      {vendorDetails.owner && vendorDetails.owner.first_name && vendorDetails.owner.last_name ?
                        `${vendorDetails.owner.first_name} ${vendorDetails.owner.last_name}` : ''}
                    </td>
                    <td>
                      {vendorDetails.owner && vendorDetails.owner.email ? vendorDetails.owner.email : ''}
                    </td>
                    <td>
                      {vendorDetails.owner && vendorDetails.owner.phone ? vendorDetails.owner.phone : vendorDetails.phone}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      {vendorId && !performanceQP.params.category && !performanceQP.params.channel && !performanceQP.params.retailer && (
        <div className="row">
          <div className="col-md-12">
            <div>
              <div style={{ marginTop: 10, marginBottom: 10, fontSize: 15 }}>
                <strong>Payments</strong>
              </div>
              <div className="panel panel-portal">
                <div className="panel-body">
                  {!vendorStats ? (
                    <p className="text-center text-muted">No payment data available.</p>
                  ) : (
                    <QuarterlyPaymentRow stats={vendorStats} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <PerformanceSummaryRow
        loading={loading}
        totals={totals}
        salesPercentage={salesPercentage}
        royaltiesPercentage={royaltiesPercentage}
        unitsPercentage={unitsPercentage}
        avgUnitSalesPercentage={avgUnitSalesPercentage}
        avgUnitRoyaltiesPercentage={avgUnitRoyaltiesPercentage}
        isCurrent={stats.isCurrent}
      />

      <div className="row">
        <TopSellingDesignRow
          accountId={routeParams.licensorId || (user && user.account ? user.account.id : '')}
          vendorId={vendorId}
          hideIfNoResults={true}
        />
      </div>

      {stats.salesDataDisplayable && (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <CategoryPerformance
                    categories={stats.categories}
                    loading={loading}
                    large={false}
                    isAggregate={stats.isAggregate}
                    onDashboard={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <ChannelPerformance
                    channels={stats.channels}
                    loading={loading}
                    large={false}
                    isAggregate={stats.isAggregate}
                    onDashboard={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <RetailerPerformance
                    retailers={stats.retailers}
                    loading={loading}
                    large={false}
                    isAggregate={stats.isAggregate}
                    onDashboard={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </FullContent>
  );
};
