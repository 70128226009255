import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle, faChevronLeft, faChevronRight, faCircle, faCogs, faExternalLink, faPlus, faSpinner, faTh, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { DropdownButton, MenuItem, Modal, OverlayTrigger, Popover, SplitButton } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory, useParams, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import { Vendor } from '../../../shared';
import User from '../../../shared/User';
import { WebBotSeller } from '../../../shared/WebBotSeller';
import { WebBotUrl, WebBotUrlStatuses } from '../../../shared/WebBotUrl';
import { ContentWithSidebar, FullContent } from '../../ContentFrame';
import { AffinityLeafletImage, CurrencyLabel, ExternalPlatformLogo, GatewayModal, LicensorTypeahead, LoadingSpinner, ModalType, TrademarkTypeahead, VendorTypeahead } from '../../shared';
import { WebBotUrlFilters } from '../WebBotUrlFilters';
import { MassReviewWebBotUrlsModal } from './MassApproveWebBotUrlsModal';

export const WebBotUrlPage = () => {
  const match = useRouteMatch();
  const params = useParams();
  const history = useHistory();
  const user = React.useContext(UserContext);
  const [url, setUrl] = React.useState<WebBotUrl>(new WebBotUrl());
  const [loading, setLoading] = React.useState(true);
  const [submittingReview, setSubmittingReview] = React.useState(false);
  const [filters, setFilters] = React.useState(WebBotUrlFilters.getInitialFilters(location.search));
  const [navigation, setNavigation] = React.useState<{next: number | null, previous: number | null, next_page: boolean, previous_page: boolean}>({ next: null, previous: null, previous_page: false, next_page: false });
  const [submitting, setSubmitting] = React.useState(false);
  const [accountLinkModal, setAccountLinkModal] = React.useState(false);
  const [accountCreateModal, setAccountCreateModal] = React.useState(false);
  const [selectedVendor, setSelectedVendor] = React.useState<Vendor[]>([]);
  const [accountCreateStatus, setAccountCreateStatus] = React.useState(0);
  const [adminSettingsModal, setAdminSettingsModal] = React.useState(false);
  const [addLicensorModal, setAddLicensorModal] = React.useState(false);
  const [showIgnoreModal, setShowIgnoreModal] = React.useState(false);
  const [selectedTrademark, setSelectedTrademark] = React.useState<number | null>(null);
  const [massApprovalModal, setMassApprovalModal] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);
  const adminSettingsForm = useForm();
  const addLicensorForm = useForm({ defaultValues: { licensor: [] } });
  const updateTrademarkForm = useForm({ defaultValues: { trademark: [] } });

  React.useEffect(() => {
    filters.setQueryParamsFromLocation(location.search);
    getData();

  },              [params['id']]);

  React.useEffect(() => {
    if (url.seller) updateAdminFormValues(url.seller);
  },              [adminSettingsModal]);

  if (!match || user.type !== 'admin') {
    return <Redirect to="/" />;
  }

  const getData = async () => {
    setLoading(true);
    await Promise.all([getUrl(), getNavigation()]);
    setLoading(false);

  };

  const getUrl = async () => {
    setLoading(true);
    const response = await fetch(`/api/enforcement/urls/${match.params['id']}`);
    const data = await response.json();
    const u = WebBotUrl.fromApi(data.data);
    setUrl(u);
    if (u.seller) {
      updateAdminFormValues(u.seller);
    }
    setLoading(false);

  };

  const getNavigation = async () => {
    const p = filters.generateApiParamString(match.params);
    const response = await fetch(`/api/enforcement/urls/${match.params['id']}/navigation?${p}`);
    const data = await response.json();
    setNavigation(data);
  };

  async function saveAdminSettings(values: any) {
    setSubmitting(true);

    const params = {
      ignore: values.ignoreSeller,
    };

    const response = await fetch(`/api/enforcement-bots/sellers/${url.seller.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    setSubmitting(false);
    const s = WebBotSeller.fromApi(data.data);
    updateAdminFormValues(s);
    const u = WebBotUrl.fromExisting(url);
    u.seller = s;
    setUrl(u);
    setAdminSettingsModal(false);
  }

  async function addLicensor(values: any) {
    setSubmitting(true);

    const params = {
      licensor: values.licensor[0].id,
    };

    const response = await fetch(`/api/enforcement/urls/${url.id}/add-licensor`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    setSubmitting(false);
    const u = WebBotUrl.fromApi(data.data);
    setUrl(u);
    setAddLicensorModal(false);
    addLicensorForm.reset();
  }

  async function updateTrademark(values: any) {
    setSubmitting(true);

    const params = {
      licensor: selectedTrademark,
      trademark: values.trademark[0].id,
    };

    const response = await fetch(`/api/enforcement/urls/${url.id}/update-trademark`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    setSubmitting(false);
    const u = WebBotUrl.fromApi(data.data);
    setUrl(u);
    setSelectedTrademark(null);
    updateTrademarkForm.reset();
  }

  const ignoreSeller = async () => {
    setSubmitting(true);
    await fetch(`/api/enforcement-bots/sellers/${url.seller.id}/ignore-all/`, { method: 'POST' });
    setShowIgnoreModal(false);
    setSubmitting(false);
  };

  const updateAdminFormValues = (seller: WebBotSeller) => {
    adminSettingsForm.setValue('ignoreSeller', seller.ignoreSeller);
  };

  const refreshContent = async () => {
    setRefreshing(true);
    const res = await fetch(`/api/enforcement/urls/${url.id}/scrape-content`, { method: 'POST' });
    const data = await res.json();
    setUrl(WebBotUrl.fromApi(data.data));
    setRefreshing(false);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const getBackRoute = () => {
    if (params['licensorId']) {
      return `/clients/${params['licensorId']}/enforce/content?${filters.generateQueryParamString()}`;
    }
    if (params['vendorId']) {
      return `/vendors/${params['vendorId']}/enforce/content?${filters.generateQueryParamString()}`;
    }
    return `/enforce/content?${filters.generateQueryParamString()}`;
  };

  const reviewUrl = async (status: WebBotUrlStatuses) => {
    setSubmittingReview(true);
    const response = await fetch(`/api/enforcement/urls/${match.params['id']}/update-status?status=${status}`, {
      method: 'POST' });
    const data = await response.json();
    const u = WebBotUrl.fromApi(data.data);
    setUrl(u);
    setSubmittingReview(false);
    goNext();

  };

  const goNext = () => {
    if (navigation.next) {
      history.push(`${navigation.next}?${filters.generateQueryParamString()}`);
    }
  };

  const linkAccount = async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    const params = {
      vendor_account_id: selectedVendor[0].id,
    };
    const response = await fetch(`/api/enforcement-bots/sellers/${url.seller.id}/assign-vendor?vendor_account_id=${params.vendor_account_id}`, {
      method: 'POST',
    });
    const data = await response.json();
    const s = WebBotSeller.fromApi(data.data);
    updateAdminFormValues(s);
    const u = WebBotUrl.fromExisting(url);
    u.seller = s;
    setUrl(u);
    setAccountLinkModal(false);
    setSubmitting(false);
    setSelectedVendor([]);
  };

  const createAccount = async () => {
    if (submitting) return;

    setSubmitting(true);
    const response = await fetch(`/api/enforcement-bots/sellers/${url.seller.id}/create-vendor?status=${accountCreateStatus}`, {
      method: 'POST',
    });
    const data = await response.json();
    const s = WebBotSeller.fromApi(data.data);
    const u = WebBotUrl.fromExisting(url);
    u.seller = s;
    updateAdminFormValues(s);
    setUrl(u);
    setAccountCreateModal(false);
    setSubmitting(false);
  };

  const toggleSellerAuthorization = async (licensor: number, authorized: boolean) => {
    setSubmitting(true);
    const data = {
      authorized,
      licensor_id: licensor,
    };
    const u = url;
    url.seller.toggleAuthorization(licensor);
    setUrl(u);
    const response = await fetch(`/api/enforcement/urls/${url.id}/toggle-licensor-authorization`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const d = await response.json();
    const w = WebBotUrl.fromApi(d.data);
    setUrl(w);
    setSubmitting(false);
  };

  const rescanSeller = async () => {
    await fetch(`/api/enforcement-bots/sellers/${url.seller.id}/rescan`, { method: 'POST' });
    window['growl']('Scan Submitted'); // not sure if I like this yet
  };

  const removeLicensor = async (licensor: number, inverse: boolean) => {
    setSubmitting(true);
    url.removeLicensor(licensor, inverse);
    setUrl(WebBotUrl.fromExisting(url));
    setSubmitting(false);
    await fetch(`/api/enforcement-bots/urls?licensorId=${licensor}&webBotUrlId=${url.id}&sellerId=${url.seller.id}&inverse=${inverse ? 1 : 0}`, { method: 'DELETE' });
  };

  const nextUrl = () => {
    const pageUpdate = navigation.next_page ? { page: filters.page + 1 } : undefined;
    return `${navigation.next || params['id']}?${filters.generateQueryParamString(pageUpdate)}`;
  };

  const previousUrl = () => {
    const pageUpdate = navigation.previous_page ? { page: filters.page - 1 } : undefined;
    return `${navigation.previous || params['id']}?${filters.generateQueryParamString(pageUpdate)}`;
  };

  const sidebar = (<div>
    <div className="panel panel-portal">
      <div className="panel-body">
        <div className="enforce-side-nav-actions">
          <Link to={previousUrl()} className="btn btn-default">
            <FontAwesomeIcon icon={faChevronLeft} />
          </Link>
          <Link to={getBackRoute()} className="btn center-button btn-default">
            <FontAwesomeIcon icon={faTh} />
          </Link>
          <Link to={nextUrl()} className="btn btn-default">
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>

        </div>
        <div className="enforce-side-platform-seller">
          <div className="platform">
            <ExternalPlatformLogo size={25} type={url.type} />
            <h5><strong>{url.type.name}</strong></h5>
          </div>
          <div className="seller">
            <div className="seller-image-actions-grid">
              <div></div>
              <img src={url.seller.image ? url.seller.image.getSize('th') : ''} />
              <DropdownButton componentClass="div" pullRight={true} noCaret={true} bsStyle="link" id="case-action" title={(<span>
                <FontAwesomeIcon className="text-muted" icon={faCogs} />
              </span>)}>
                {url.type.id === 2 ? <MenuItem onClick={rescanSeller}>Rescan Seller</MenuItem> : null}
                <MenuItem onClick={() => setShowIgnoreModal(true)}>Ignore and Reset Seller</MenuItem>
                <MenuItem onClick={() => setAdminSettingsModal(true)}>Admin Settings</MenuItem>
                <MenuItem onClick={() => setMassApprovalModal(true)}>Mass Review Content</MenuItem>
              </DropdownButton>

            </div>
            <h5><strong>{url.seller.platformUsername}</strong></h5>
          </div>
          <div className="platform-links">
            <a target="_blank" href={url.fullUrl} >View Content <FontAwesomeIcon icon={faExternalLink} /></a>
            {url.seller.sellerUrl ? <a target="_blank" href={url.seller.sellerUrl} >Seller Profile <FontAwesomeIcon icon={faExternalLink} /></a> : null}

          </div>
          <div className="seller-license-info text-muted">
            <span>{url.seller.sellerLicensors.length} licensors</span>
            {url.possibleLicensed ?
              <OverlayTrigger trigger="hover" placement="top" overlay={
                <Popover title="Possibly Licensed">
                  References to licensing were found when scanning this product.
                </Popover>
              }>
                <span> &#x2022; Possibly licensed</span>
              </OverlayTrigger> : null}
          </div>
        </div>

        <div className="enforce-alerts-block">
          <div className={`alert alert-${url.seller.linkedAccountAlertColor}`}>{url.seller.linkedAccount ?  `Linked ${url.seller.linkedAccountAlertLabel} account` : 'No linked account'}</div>
          <div className={`alert alert-${url.seller.wasAuthorizedUrl(url) ? 'success' : 'danger'}`}>{url.seller.wasAuthorizedUrl(url) ? 'Previous Authorization' : 'No previous authorization'}</div>
        </div>
        <div className="text-center enforce-sidebar-status">
          {submittingReview ? <span>
            <FontAwesomeIcon spin icon={faSpinner} />
          </span> : <span>
            <FontAwesomeIcon className={url.statusColor} icon={faCircle} /> <strong>{url.status.name}</strong>
          </span>}
        </div>

        {url.status.id === WebBotUrlStatuses.NeedsReview ? <div className="enforce-primary-review-actions">
          <button onClick={() => reviewUrl(WebBotUrlStatuses.Authorized)} className="btn  btn-primary btn-block">
            Authorize
          </button>
          <button onClick={() => reviewUrl(WebBotUrlStatuses.NeedsTakedown)} className="btn btn-danger bt-block">
            Takedown
          </button>
        </div> : null}
        <DropdownButton componentClass="div" className="btn-block btn-default"  id="status-buttton" title="Additional Options">
          <MenuItem onClick={() => reviewUrl(WebBotUrlStatuses.NeedsReview)} key={WebBotUrlStatuses.NeedsReview} >
            Needs Review
          </MenuItem>
          <MenuItem onClick={() => reviewUrl(WebBotUrlStatuses.Ignored)} key={WebBotUrlStatuses.Ignored} >
            Ignore
          </MenuItem>
          <MenuItem onClick={() => reviewUrl(WebBotUrlStatuses.Removed)} key={WebBotUrlStatuses.Removed} >
            Removed
          </MenuItem>
          <MenuItem onClick={() => reviewUrl(WebBotUrlStatuses.NeedsTakedown)} key={WebBotUrlStatuses.NeedsTakedown} >
            Needs Takedown
          </MenuItem>
          <MenuItem onClick={() => reviewUrl(WebBotUrlStatuses.PendingRemoval)} key={WebBotUrlStatuses.PendingRemoval} >
            Pending Removal
          </MenuItem>

        </DropdownButton>

      </div>

    </div>
    <div className="panel panel-portal">
      <div className="panel-body">
        <h5><strong>Linked Account</strong></h5>
        {url.seller.linkedAccount ? <div className="enforce-linked-account">
          <img src={url.seller.linkedAccount.logo.getSize('th')} />
          <div className="enf-account-status">
            <span><strong><Link target="_blank" to={`/vendors/${url.seller.linkedAccount.id}/enforce`}  >{url.seller.linkedAccount.accountName}</Link></strong></span>
            <div>{url.seller.linkedAccount.status.display}</div>
          </div>

        </div> : <div>
            <button onClick={() => setAccountLinkModal(true)} className="btn btn-block btn-primary">Link Account</button>
            <button onClick={() => setAccountCreateModal(true)} className="btn btn-block btn-primary">Create Account</button>

          </div>}
      </div>
    </div>
    <div className="panel panel-portal">
      <div className="panel-body">
        <h5><strong>Details</strong></h5>
        <div className="enforce-details-grid">
          <div className="enforce-url-details half">
            <span className="text-muted">Last Checked</span>
            <span>{url.createdAt.format('M/DD/YY')}</span>
          </div>
          <div className="enforce-url-details half">
            <span className="text-muted">Last Updated</span>
            <span>{url.updatedAt.format('M/DD/YY')}</span>
          </div>
          <div className="enforce-url-details">
            <span className="text-muted">External Id</span>
            <span>{url.platformIdentifier}</span>
          </div>
          <div className="enforce-url-details">
            <span className="text-muted">External Url</span>
            <span><a target="_blank" href={url.fullUrl} >{url.fullUrl}</a></span>
          </div>
          <div className="enforce-url-details half">
            <span className="text-muted">Brand</span>
            <span>{url.brand || 'N/A'}</span>
          </div>
          <div className="enforce-url-details half">
            <span className="text-muted">Manufacturer</span>
            <span>{url.manufacturer || 'N/A'}</span>
          </div>
          <div className="enforce-url-details">
            <button onClick={refreshContent} className="btn btn-default btn-block btn-sm">{refreshing ? 'Refreshing...' : 'Refresh Content'}</button>
          </div>

        </div>

      </div>
    </div>

  </div>);
  const body = (<div>
    <div className="panel panel-portal">
      <div className="panel-body">
        {url.correctImage('or') ? <AffinityLeafletImage image={url.correctImage('or')} /> :
          <div>
            <h3 className="text-center text-muted">No Image Found</h3>
            <WebBotUrlManuallyUploadImage id={params['id']} onSuccess={setUrl} />
          </div>}

        <h4><strong>{url.name}</strong></h4>
        <p className="small text-muted">{url.description}</p>

        {url.tags.map(t => (
          <span style={{ marginTop: 5, marginBottom: 5, margin: 5 }} className="label label-primary">{t}</span>
        ))}

        <p><strong>Price: </strong><CurrencyLabel value={url.price} /></p>

        <p className="small text-muted">Image from {url.image ? 'Affinity' : url.type.name} {url.image ? null : <a href={url.imageUrl} target="_blank">View on {url.type.name}</a>}</p>

      </div>
    </div>
    <div className="panel panel-portal">
      <div className="panel-body">
        <div className="enforce-url-licensors">
          <div className="enforce-url-licensor header">
            <span><strong>Licensor</strong></span>
            <span className="text-center"><strong>Trademark</strong></span>
            <span className="text-right"><strong>Authorization</strong></span>
          </div>
          {url.licensors.map((l: any) => <div className="enforce-url-licensor">
            <div className="url-licensor-col">
              <span className="remove-icon">

                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="top"
                  overlay={
                    <Popover id={`licensor-remove-${l.id}`}>
                      <div>
                        <button onClick={() => removeLicensor(Number(l.id), false)} className="btn btn-default btn-block">Remove Licensor</button>
                        <button onClick={() => removeLicensor(Number(l.id), true)} className="btn btn-default btn-block">Remove Other Licensors</button>
                      </div>
                    </Popover>
                  }

                >
                  <span><FontAwesomeIcon icon={faTimes} /></span>
                </OverlayTrigger>

              </span>
              <img src={l.affinityImage.getSize('th')} className="img-responsive" />
              <strong>{l.shortName}</strong>
            </div>
            <div onClick={() => setSelectedTrademark(Number(l.id))} className="url-trademark-col">
              {l.trademarks.length ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                {l.trademarks[0].image ? <img src={l.trademarks[0].image.getSize('th')} className="img-responsive" /> : l.trademarks[0].title }
              </div>
                : <div className="text-center">No trademark <span>Set</span></div>}
            </div>
            <div className="url-auth-col">
              <div onClick={() => toggleSellerAuthorization(Number(l.id), !url.seller.wasAuthorized(Number(l.id)))}>
                {url.seller.wasAuthorized(Number(l.id)) ? <span className="text-success">
                  <FontAwesomeIcon icon={faCheckCircle} /> Authorized

                </span> : <span className="text-danger"><FontAwesomeIcon icon={faTimesCircle} /> Not Authorized</span>}
              </div>

            </div>

          </div>)}

        </div>
        <div onClick={() => setAddLicensorModal(true)} className="enforce-url-add-licensor">
          <FontAwesomeIcon icon={faPlus} /> Add Licensor

        </div>
      </div>
    </div>
    <GatewayModal
      type={ModalType.Primary}
      shown={accountLinkModal}
      onClose={() => setAccountLinkModal(false)}
      title={'Link Account'}
    >
      <Modal.Body>
        <p>
          Search all vendor accounts and select an account to link with this seller.
        </p>
        <VendorTypeahead selected={selectedVendor} onChange={setSelectedVendor}   multiple={false}/>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => setAccountLinkModal(false)} className="btn btn-default">
          Close
        </button>
        <button onClick={linkAccount} className="btn btn-primary pull-right">{submitting ? 'Linking...' : 'Link Vendor'}</button>
      </Modal.Footer>
    </GatewayModal>
    <GatewayModal
      type={ModalType.Primary}
      shown={accountCreateModal}
      onClose={() => setAccountCreateModal(false)}
      title={'Create Account'}
    >
      <Modal.Body>
        <p>
          Are you sure you want to create a new account?
        </p>
        <label>Account Status</label>
        <select name="status" className="form-control" value={accountCreateStatus} onChange={e => setAccountCreateStatus(Number(e.target.value))}>
          <option value="1">Potential</option>
          <option value="2">Current</option>
          <option value="3">Cancelled</option>
          <option value="4">Registered</option>
          <option value="5">Illegal</option>
          <option value="6">Out of Business</option>
          <option value="7">Non-Producing</option>
          <option value="8">One Time</option>
          <option value="11">Non-Issue</option>
          <option value="12">Retailer</option>
        </select>

      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => setAccountCreateModal(false)} className="btn btn-default">
          Close
        </button>
        <button onClick={createAccount} className="btn btn-primary pull-right">{submitting ? 'Creating..' : 'Create Account'}</button>
      </Modal.Footer>
    </GatewayModal>
    <GatewayModal
      type={ModalType.Primary}
      shown={addLicensorModal}
      onClose={() => setAddLicensorModal(false)}
      title={'Add Licensor'}
    >
      <form onSubmit={addLicensorForm.handleSubmit(addLicensor)}>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label>Licensor</label>
              <LicensorTypeahead
                selected={addLicensorForm.watch('licensor')}
                onChange={l => addLicensorForm.setValue('licensor', l)}
                multiple={false}
              />
            </div>
            <p className="text-center">Licensor will be added with their default trademark.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={() => setAddLicensorModal(false)} className="btn btn-default">
            Close
          </button>
          <button type="submit" className="btn btn-primary pull-right">{submitting ? 'Adding...' : 'Add Licensor'}</button>
        </Modal.Footer>
      </form>
    </GatewayModal>
    <GatewayModal
      type={ModalType.Primary}
      shown={selectedTrademark !== null}
      onClose={() => setSelectedTrademark(null)}
      title={'Update Trademark'}
    >
      <form onSubmit={updateTrademarkForm.handleSubmit(updateTrademark)}>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label>Trademark</label>
              {selectedTrademark ? <TrademarkTypeahead
                accountId={selectedTrademark}
                selected={updateTrademarkForm.watch('trademark')}
                onChange={l => updateTrademarkForm.setValue('trademark', l)}
                multiple={false}
              /> : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={() => setSelectedTrademark(null)} className="btn btn-default">
            Close
          </button>
          <button type="submit" className="btn btn-primary pull-right">{submitting ? 'Updating...' : 'Update Trademark'}</button>
        </Modal.Footer>
      </form>
    </GatewayModal>
    <GatewayModal
      type={ModalType.Primary}
      shown={adminSettingsModal}
      onClose={() => setAdminSettingsModal(false)}
      title={'Admin Settings'}
    >
      <form onSubmit={adminSettingsForm.handleSubmit(saveAdminSettings)}>
        <Modal.Body>
          <div>

            <div className="form-group">
              <strong>Ignore Seller</strong>
              <div className="checkbox">
                <label htmlFor="ignoreSeller"> <input type="checkbox" {... adminSettingsForm.register('ignoreSeller')} />  Ignore Seller</label>
              </div>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={() => setAdminSettingsModal(false)} className="btn btn-default">
            Close
          </button>
          <button type="submit" className="btn btn-primary pull-right">{submitting ? 'Saving...' : 'Save'}</button>
        </Modal.Footer>
      </form>
    </GatewayModal>
    <GatewayModal
      type={ModalType.Primary}
      shown={showIgnoreModal}
      onClose={() => setShowIgnoreModal(false)}
      title={'Ignore and Reset'}
    >
      <Modal.Body>
        <p>
          Are you sure you want to ignore and reset this seller? This will remove all tagged licensors and ignore all products. The seller will be excluded from future scans.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => setShowIgnoreModal(false)} className="btn btn-default pull-left">
          Cancel
        </button>
        <button onClick={ignoreSeller} className="btn btn-primary pull-right">{submitting ? 'Saving...' : 'Ignore Seller'}</button>
      </Modal.Footer>
    </GatewayModal>
    <MassReviewWebBotUrlsModal shown={massApprovalModal} onClose={() => setMassApprovalModal(false)} onSuccess={goNext} seller={url.seller} status={url.status} />

  </div>);

  return (
    <FullContent >
      <ContentWithSidebar columnSizes={{ main: 'col-xl-9 col-lg-8 col-md-7', sidebar: 'col-xl-3 col-lg-4 col-md-5' }} main={body} sidebar={sidebar} />
    </FullContent>
  );
};

const WebBotUrlManuallyUploadImage = (props: { id: number, onSuccess: (url: WebBotUrl) => void }) => {
  const [submitting, setSubmitting] = React.useState(false);
  const uploadForm = useForm<{ file: FileList }>();
  const { id, onSuccess } = props;

  const uploadImage = async (values: { file: FileList }) => {
    if (submitting) return;
    setSubmitting(true);
    const image = values.file[0];
    const formData = new FormData();
    formData.append('image', image, image.name);
    const response = await fetch(`/api/enforcement/urls/${id}/upload-image`, { method: 'POST', body: formData });
    const data = await response.json();
    const u = WebBotUrl.fromApi(data.data);
    onSuccess(u);
    setSubmitting(false);

  };

  return (
    <form style={{ display: 'flex',  alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom: 20 }} onSubmit={uploadForm.handleSubmit(uploadImage)}>

      <div className="form-group no-margin">
        <input
          type="file"
          multiple={false}
          accept="image/png, image/jpeg"
          {... uploadForm.register('file', { required: true })}
        />
      </div>

      <button disabled={submitting} className="btn btn-primary">{submitting ?  'Uploading...' : 'Upload Image'}</button>

    </form>

  );
};
